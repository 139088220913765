import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import BasicButton from "./buttons/basicButton"

import WithIdentity, { NetlifyContext } from "./identity/withIdentity"

interface NetlifyUser {
  email: string
}

/**
 * Publicly required props for this component
 */
interface NetlifyIdentityProps {
  redirectUrl: string
  shouldRedirect: boolean
  expectedEmail: string
  onLogin?: (user: NetlifyUser) => any
}

const NetlifyIdentity = (props: NetlifyIdentityProps & NetlifyContext) => {
  const {
    redirectUrl,
    expectedEmail,
    shouldRedirect,
    isAuthenticated,
    user,
    authenticate,
    signout,
  } = props

  const login = () => {
    authenticate(expectedEmail, user => {
      if (!user) {
        console.log("No user!")
        return
      }
      console.log("Authenticated!")
    })
  }

  const logout = () => {
    signout(() => {
      console.log("Signed out!")
    })
  }

  React.useEffect(() => {
    if (
      shouldRedirect &&
      isAuthenticated &&
      user !== null &&
      user.email === expectedEmail
    ) {
      navigate(redirectUrl)
    }
  }, [isAuthenticated, user])

  return (
    <React.Fragment>
      <BasicButton handleClick={login}>Login</BasicButton>
      <BasicButton handleClick={logout}>Logout</BasicButton>
    </React.Fragment>
  )
}

export default WithIdentity(NetlifyIdentity) as React.FC<NetlifyIdentityProps>
