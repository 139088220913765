import React from "react"
import netlifyIdentity, { User } from "netlify-identity-widget"
import { any } from "prop-types"

interface AuthenticateCallback {
  (user: User): void
}

interface SignoutCallback {
  (): void
}

export interface NetlifyContext {
  isAuthenticated: boolean
  user: User
  authenticate: (expectedEmail: string, callback: AuthenticateCallback) => any
  signout: (callback: SignoutCallback) => any
}

const defaultNetlifyState: NetlifyContext = {
  isAuthenticated: false,
  user: null,
  authenticate: () => {},
  signout: () => {},
}

export const IdentityContext = React.createContext<NetlifyContext>(
  defaultNetlifyState
)

const IdentityProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [user, setUser] = React.useState<User>(null)

  React.useEffect(() => {
    const currentUser = netlifyIdentity.currentUser()

    if (currentUser !== null) {
      setUser(currentUser)
      setIsAuthenticated(true)
    }
  }, [])

  const authenticate = (
    expectedEmail: string,
    callback: AuthenticateCallback
  ) => {
    console.debug("starting authenticate")

    const currentUser = netlifyIdentity.currentUser()

    if (currentUser === null || currentUser.email !== expectedEmail) {
      console.debug("no user, starting authenticate process")
      netlifyIdentity.open()
      netlifyIdentity.on("login", (user: User) => {
        console.log(user, expectedEmail)
        if (user.email !== expectedEmail) {
          callback(null)
          setIsAuthenticated(null)
          setUser(null)
          netlifyIdentity.logout()
        } else {
          callback(user)
          setUser(user)
          setIsAuthenticated(true)
          netlifyIdentity.close()
        }
      })
    } else {
      callback(currentUser)
      setUser(currentUser)
      setIsAuthenticated(true)
    }
  }

  const signout = (callback: SignoutCallback) => {
    netlifyIdentity.logout()
    netlifyIdentity.on("logout", () => {
      setUser(null)
      setIsAuthenticated(null)
    })
  }

  const value = {
    isAuthenticated,
    user,
    authenticate,
    signout,
  }

  return (
    <IdentityContext.Provider value={value}>
      {props.children}
    </IdentityContext.Provider>
  )
}

let instance: JSX.Element

const WithIdentityProvider = ChildComponent => (props: any) => {
  if (!instance) {
    instance = (
      <IdentityProvider>
        <IdentityContext.Consumer>
          {({ isAuthenticated, user, authenticate, signout }) => {
            return (
              <ChildComponent
                {...props}
                isAuthenticated={isAuthenticated}
                user={user}
                authenticate={authenticate}
                signout={signout}
              />
            )
          }}
        </IdentityContext.Consumer>
      </IdentityProvider>
    )
  }
  return instance
}

export default WithIdentityProvider
