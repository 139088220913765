import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled, { keyframes } from "styled-components"
import typography from "../utils/typography"
import SEO from "../components/seo"

import Layout, { DetailSection } from "../components/layout"
import BasicButton from "../components/buttons/basicButton"
import NetlifyIdentity from "../components/netlifyIdentity"

import { HeroWrapper, HeroHeading } from "../components/hero/heroTitle"
import Reveal from "../components/reveal"

const Hero = styled.div`
  display: grid;
  min-height: 20vh;
  grid-column: main / gutter-right;
  align-content: flex-end;

  @media screen and (min-width: 65rem) {
    min-height: 19.5rem;
  }
`

const Content = styled.div`
  max-width: 17.75rem;
  margin-top: 2.5rem;
  margin-left: auto;
  font-size: 0.875rem;

  @media screen and (min-width: 65rem) {
    max-width: 24.5rem;
    margin-top: 6.5rem;
    font-size: 1rem;
  }
`

const Offset = styled.div`
  display: grid;
  grid-column: 2 / -1; /* start position from DetailSection */

  @media screen and (min-width: 46rem) {
    grid-column: 3 / -1; /* start position from DetailSection */
  }

  @media screen and (min-width: 56rem) {
    grid-column: 4 / -1; /* start position from DetailSection */
  }
`

const P = styled.p`
  margin-bottom: 1.5rem;
`

const StrongText = styled.strong`
  font-weight: 600;
`

const StyledForm = styled.div`
  label {
    font-size: 1rem;
  }
`

const GuestFound = ({ location }) => {
  const [emailSent, setEmailSent] = useState(false)

  const email = location.state && location.state.email

  // Protect against people navigating directly to this page
  useEffect(() => {
    if (!email) {
      navigate("/404")
    }
  }, [])

  const cancel = () => {
    navigate("/", { state: {} })
  }

  const showConfirmation = () => {
    setEmailSent(true)
  }

  // TODO: Ensure this matches up with the Netlify user, so people can't confirm an email that doesn't match

  return (
    <Layout>
      <Hero>
        <HeroWrapper>
          <HeroHeading>Success</HeroHeading>
        </HeroWrapper>
      </Hero>

      <DetailSection>
        <Offset>
          <Reveal delay="2.15s">
            <Content>
              <P>Confirmed! Log in to set your RSVP.</P>
              <NetlifyIdentity
                expectedEmail={email}
                redirectUrl={`/rsvp?email=${email}`}
                shouldRedirect={true}
              />
              <BasicButton handleClick={cancel}>Go home</BasicButton>
            </Content>
          </Reveal>
        </Offset>
      </DetailSection>
    </Layout>
  )
}

export default GuestFound
